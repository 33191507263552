import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"   transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">


<path d="M1390 2370 c-306 -38 -551 -216 -679 -492 -49 -105 -70 -189 -78
-304 -22 -337 156 -652 458 -809 139 -72 217 -90 394 -90 177 0 255 18 394 90
250 130 424 382 456 660 l6 60 -81 -3 c-73 -3 -80 -5 -68 -19 12 -15 11 -15
-9 -3 -37 24 -69 1 -89 -66 l-18 -56 29 -22 c36 -26 75 -66 75 -77 0 -15 -44
-10 -68 6 -12 9 -25 30 -30 48 l-8 32 -26 -55 c-30 -66 -106 -152 -148 -170
-43 -18 -47 -6 -6 19 64 40 145 147 162 214 4 20 1 33 -11 47 -10 11 -13 20
-7 20 6 0 14 -5 17 -10 8 -13 25 -3 25 15 0 8 -12 17 -27 21 -43 10 -73 32
-73 54 0 19 2 20 28 6 15 -8 36 -23 46 -35 26 -28 33 -27 38 10 4 22 0 32 -11
36 -45 13 -113 49 -130 67 -20 21 -26 42 -23 79 4 44 64 3 124 -86 23 -34 43
-56 45 -50 4 10 -4 60 -22 143 -5 22 -3 21 10 -5 14 -27 15 -28 13 -6 -3 26
-11 31 -80 56 -48 17 -134 73 -125 81 5 5 126 -54 159 -78 23 -16 32 -5 18 22
-20 37 -23 144 -6 185 9 21 21 35 28 33 20 -7 21 -85 3 -141 -15 -47 -13 -107
5 -107 4 0 10 12 13 28 10 49 50 96 94 112 23 8 45 11 49 8 13 -13 -32 -69
-92 -114 -38 -29 -60 -52 -57 -61 3 -7 10 -13 15 -13 7 0 7 -5 0 -13 -10 -12
-16 -167 -7 -167 2 0 37 14 78 30 82 33 116 37 136 18 11 -11 12 1 7 67 -18
234 -134 454 -316 602 -167 136 -420 209 -630 183z m255 -51 c89 -16 207 -65
289 -119 137 -92 297 -288 266 -325 -21 -26 -50 -18 -50 13 -1 39 -32 76 -67
77 -19 1 -32 9 -40 26 -7 14 -24 36 -38 49 -33 31 -55 20 -74 -41 -8 -24 -22
-60 -32 -79 -15 -30 -16 -43 -7 -79 8 -31 8 -45 -1 -54 -17 -17 -14 -32 13
-64 24 -29 24 -29 5 -53 -10 -14 -19 -35 -19 -48 0 -27 22 -73 44 -91 9 -8 16
-28 16 -46 0 -25 9 -42 35 -66 64 -60 41 -132 -79 -248 -55 -52 -66 -77 -45
-98 21 -21 -181 -136 -240 -136 -35 0 -217 38 -277 59 -87 28 -191 83 -251
131 -99 79 -153 152 -224 305 -36 76 -50 252 -28 346 35 144 140 294 263 373
208 135 478 125 653 -25 48 -42 58 -45 42 -13 -21 40 -72 79 -159 121 l-85 41
-120 0 c-131 0 -186 -11 -277 -57 -32 -15 -65 -28 -74 -28 -31 0 -169 -150
-207 -226 -57 -114 -71 -174 -71 -304 0 -134 17 -206 74 -326 108 -225 389
-401 645 -405 121 -1 41 -18 -91 -18 -146 -1 -239 20 -352 79 -202 105 -326
264 -368 469 -37 187 18 402 143 555 69 85 218 212 288 245 150 72 318 92 500
60z m368 -359 c9 -25 19 -72 22 -105 6 -53 5 -58 -9 -46 -31 26 -66 113 -66
166 0 29 3 55 7 58 9 9 27 -19 46 -73z m-24 -124 c29 -37 50 -71 47 -73 -10
-10 -83 40 -99 67 -18 29 -24 102 -8 85 5 -6 32 -41 60 -79z m302 -153 c-19
-36 -157 -150 -168 -139 -10 10 27 83 56 109 36 34 61 46 94 47 23 0 26 -3 18
-17z m-1 -92 c0 -11 -9 -21 -22 -24 -31 -9 -33 -2 -7 22 28 26 29 26 29 2z
m-1600 -129 c0 -20 7 -62 15 -92 46 -174 185 -340 355 -423 117 -58 187 -77
310 -84 247 -15 546 122 648 296 14 24 28 39 30 33 5 -16 -71 -143 -128 -211
-126 -151 -346 -241 -560 -228 -119 7 -193 26 -295 77 -231 114 -368 324 -391
603 -5 59 -4 78 4 73 7 -4 12 -24 12 -44z m1580 -70 c0 -12 -46 -32 -73 -32
-12 0 -40 5 -62 12 l-40 12 35 7 c52 10 140 10 140 1z m3 -74 c-7 -50 -60
-170 -104 -239 -130 -200 -348 -336 -584 -363 l-50 -6 40 16 c22 9 69 28 105
42 36 14 93 43 127 64 127 80 253 252 253 345 0 22 2 42 5 45 3 3 23 -3 45
-14 43 -22 70 -15 90 23 9 16 7 27 -9 55 -19 31 -19 34 -3 35 13 0 80 21 89
28 1 0 -1 -14 -4 -31z"/>
<path d="M1420 2180 c-141 -18 -294 -100 -389 -208 -115 -130 -191 -351 -165
-481 12 -56 21 -32 26 67 13 228 148 433 351 531 45 22 97 42 115 45 28 5 32
3 32 -17 0 -13 -4 -27 -9 -32 -5 -6 -20 -44 -34 -85 -58 -169 -62 -179 -103
-209 -61 -43 -122 -120 -145 -181 -17 -44 -20 -72 -17 -145 3 -78 8 -100 35
-156 39 -78 102 -140 181 -177 50 -24 69 -27 162 -27 95 0 110 3 160 28 123
63 206 183 217 316 11 135 -49 257 -170 345 -20 15 -46 44 -56 64 -10 20 -38
58 -60 84 -45 50 -75 111 -86 171 l-7 37 40 0 c59 0 140 -19 192 -45 25 -12
58 -25 74 -29 26 -6 28 -5 17 8 -55 66 -232 113 -361 96z m39 -169 c7 -13 39
-55 72 -93 32 -39 63 -84 69 -102 6 -20 23 -38 46 -51 49 -26 102 -87 132
-154 38 -81 38 -192 -1 -276 -105 -226 -379 -282 -561 -115 -67 62 -99 134
-104 234 -3 66 0 92 18 140 26 68 91 144 157 183 36 21 43 30 43 56 0 18 9 47
20 65 11 18 20 45 20 60 0 15 9 42 20 60 11 18 20 41 20 52 0 10 5 31 12 47
11 27 12 26 18 -27 3 -30 12 -66 19 -79z"/>
<path d="M1400 2002 c-1 -61 94 -184 151 -196 18 -3 34 -4 37 -1 10 10 -22 35
-38 30 -22 -7 -90 69 -125 140 -18 37 -24 44 -25 27z"/>
<path d="M1360 1885 c0 -22 29 -75 40 -75 13 0 13 17 1 25 -5 3 -12 19 -16 35
-7 31 -25 42 -25 15z"/>
<path d="M1430 1805 c0 -6 25 -42 55 -80 65 -82 85 -121 85 -167 0 -25 3 -29
10 -18 8 13 10 12 10 -3 0 -10 -19 -37 -42 -60 -67 -66 -166 -86 -242 -48 -38
20 -38 20 -21 0 10 -10 26 -19 35 -19 9 0 20 -3 24 -7 8 -9 14 -10 48 -12 38
-2 6 -20 -42 -23 l-45 -3 43 -13 c85 -26 168 17 219 115 30 55 31 61 23 130
-8 76 -41 142 -95 193 -27 25 -65 34 -65 15z"/>
<path d="M1365 1770 c-5 -14 8 -38 48 -87 30 -38 62 -83 72 -102 15 -30 19
-32 27 -17 5 9 7 29 3 45 -6 28 -121 181 -136 181 -4 0 -10 -9 -14 -20z"/>
<path d="M1325 1663 c-3 -16 -16 -39 -28 -53 -17 -19 -14 -18 10 4 32 29 33
29 53 11 12 -11 16 -21 10 -25 -10 -6 25 -82 41 -88 10 -4 0 -32 -11 -32 -5 0
-25 27 -46 61 -20 33 -39 56 -43 51 -3 -6 -13 -12 -21 -14 -8 -2 -26 -21 -41
-43 -21 -29 -25 -43 -18 -57 10 -18 20 -24 18 -10 -4 21 4 33 31 47 29 15 29
15 60 -30 33 -47 47 -55 56 -30 7 17 34 21 34 5 0 -19 18 -10 24 13 4 16 8 19
14 9 10 -15 19 -6 42 37 l13 24 -29 -22 -28 -22 -11 30 c-8 23 -111 161 -121
161 -1 0 -5 -12 -9 -27z"/>
<path d="M1216 1424 c-11 -28 -6 -70 9 -90 14 -18 14 -17 4 11 -6 16 -8 44 -4
62 6 35 1 44 -9 17z"/>
<path d="M1093 610 c-13 -5 -32 -24 -43 -42 -36 -59 -22 -125 32 -158 31 -19
51 -20 421 -20 426 0 431 1 462 60 8 16 15 42 15 57 0 38 -34 91 -66 103 -32
13 -791 12 -821 0z m184 -29 c21 -2 35 -39 23 -60 -7 -14 -7 -27 2 -45 11 -26
28 -35 28 -16 0 5 9 34 21 65 29 78 53 74 84 -13 13 -35 26 -66 30 -70 3 -4 5
27 4 68 l-2 75 43 -1 c32 -1 48 -7 62 -24 26 -32 23 -76 -7 -105 -20 -21 -34
-25 -84 -25 -51 0 -61 3 -66 20 -3 11 -15 20 -25 20 -10 0 -22 -9 -25 -20 -5
-16 -15 -20 -50 -20 -40 0 -44 2 -50 30 -4 17 -11 30 -16 30 -5 0 -9 -13 -9
-30 0 -23 -4 -30 -20 -30 -18 0 -20 7 -20 78 l0 77 32 -2 c18 -1 38 -2 45 -2z
m493 -23 c0 -23 4 -28 26 -28 21 0 25 4 22 23 -3 23 2 27 27 27 12 0 15 -15
15 -75 0 -72 -1 -75 -23 -75 -20 0 -23 4 -19 30 4 27 1 30 -22 30 -22 0 -26
-4 -26 -30 0 -29 -2 -30 -45 -30 -38 0 -45 3 -45 20 0 14 -7 20 -24 20 -15 0
-26 -7 -29 -20 -3 -12 -14 -20 -27 -20 -17 0 -20 4 -15 18 5 9 17 43 29 75 16
44 26 57 42 57 16 0 26 -14 47 -72 l26 -73 0 75 c1 69 3 76 21 76 15 0 20 -7
20 -28z m-612 -56 c3 -67 2 -72 -18 -72 -29 0 -70 44 -70 75 0 31 43 78 68 73
14 -3 18 -16 20 -76z m778 22 c7 -28 -9 -74 -26 -74 -5 0 -10 23 -10 50 0 37
4 50 15 50 8 0 18 -12 21 -26z"/>
<path d="M1510 505 c0 -48 12 -58 30 -24 14 26 5 69 -15 69 -11 0 -15 -12 -15
-45z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
